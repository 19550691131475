const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  languageTriggers: [],
}

const nameSettingsMasterData = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_NAME_SETTINGS':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_DATA_LANGUAGE_TRIGGERS":
      return {
        ...state,
        languageTriggers: action.data,
      };
    case 'CREATE_NAME_SETTINGS':
      return { ...state }
    case 'UPDATE_NAME_SETTINGS':
      return { ...state }

    default:
      return { ...state }
  }
}
export default nameSettingsMasterData
