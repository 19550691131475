const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    userData: [],
    trackUserData: [],
    countData:[]
  
  }
  
  const locationTracking = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_TRACKING_USER':
        return {
          ...state,
          trackUserData: action.data, 
        };
        case 'GET_COUNT':
          return {
            ...state,
            countData: action.data, 
          };
  
  
      default:
        return { ...state }
    }
  }
  export default locationTracking
  