const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: []
}

const requiredFieldMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_REQUIRED_FIELDS':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isFirstCall: action.isFirstCall
      }
    case 'CREATE_REQUIRED_FIELDS':
      return { ...state }
    case 'UPDATE_REQUIRED_FIELDS':
      return { ...state }
    case "DELETE_REQUIRED_FIELDS":
      return { ...state };
    default:
      return { ...state }
  }
}

export default requiredFieldMasterReducer
